.checkout-index-index.storecredit--enabled {

  &.storecredit--above-payments {

    .form.payments {

      > .fieldset {
        display: flex;
        flex-direction: column;

        .opc-payment {
          order: 2;
        }

        .opc-payment-additional.credit {
          order: 1;
          border-bottom: 1px solid $grey-lighter;
          margin-bottom: $global-margin * 2;
          margin-top: $global-margin * 4.75;
          padding-bottom: $global-padding * 2;

          .payment-option-title {
            border-top-width: 0;
            font-size: rem-calc(40);
            padding: 0 0 ($global-padding * 1.25) 0;

            .action-toggle:after {
              vertical-align: middle;
              margin-bottom: $global-margin / 2;
            }
          }
        }
      }
    }

    .opc-block-summary {

      .table-totals tbody {
        display: flex;
        flex-direction: column;

        .totals,
        .credit-amount {

          .mark {
            width: 85%;
          }

          .amount {
            width: 10%;
            text-align: right;
          }
        }

        .totals.sub {
          order: 1;
        }

        .totals.shipping {
          order: 2;
        }

        .credit-amount {
          order: 3;
        }

        .totals.grand {
          order: 6;
        }
      }
    }
  }
}
