body.page-layout-1column-barebones {
  background-color: $white-smoke;

  .page-wrapper {
    @include breakpoint(small only) {
      min-height: unset;
    }
  }

  .agency-barebones-content-container {
    background: $white;
    border: 1px solid darken($white-smoke, 5);
    max-width: 40%;
    margin: 15% auto;
    padding: $global-padding * 2 $global-padding * 4;

    @include breakpoint(medium down) {
      max-width: 80%;
    }

    @include breakpoint(medium down landscape) {
      max-width: 80%;
      margin-top: $global-margin * 4;
      margin-bottom: $global-margin * 4;
    }

    @include breakpoint(small only) {
      margin-top: $global-margin * 2;
      margin-bottom: $global-margin * 2;
    }

    .agency-barebones-header {
      margin-bottom: $global-margin * 2;

      .logo {
        float: unset;

        img {
          margin: 0 auto;
          width: auto;
          height: auto;
        }
      }
    }

    .action.captcha-reload {
      @include button ($type: primary, $outline: true);
    }

    .form {

      &.password.reset,
      &.send.confirmation,
      &.password.forget,
      &.create.account {
        min-width: unset;
        width: 100%;

        .action {

          &.primary {
            @include button($type: primary);
            font-size: rem-calc(24);
          }
        }
      }
    }

    .block-customer-login {
      @include breakpoint(small only) {
        margin-bottom: 0;
      }
    }

    .agency-barebones-footer {
      border-top: 1px solid $white-smoke;
      margin-top: $global-margin * 2;
      padding: $global-padding * 2 0;

      @include breakpoint(small only) {
        padding: ($global-padding * 2) 0 0;
        margin-top: 0;
      }

      .branding-footer {
        float: left;
        width: 100%;
        padding-bottom: $global-padding * 2;

        @include breakpoint(small only) {
          display: block;
          float: unset;
          padding-bottom: 0;
          width: 100%;
        }

        .branding-logo {
          background: url('../images/logo.svg') no-repeat center center;
          background-size: 80%;
          float: left;
          width: 50%;
          margin: 0 auto;
          padding: $global-padding * 3 0;

          @include breakpoint(small only) {
            background-size: 70%;
            display: block;
            float: unset;
            width: 100%;
          }
        }

        .branding-contact {
          float: right;
          font-size: rem-calc(32);
          padding: $global-padding 0 $global-padding ($global-padding * 2);
          width: 40%;

          @include breakpoint(small only) {
            display: block;
            float: unset;
            text-align: center;
            width: 100%;
            padding: $global-padding;
          }

          a {
            @include button ($type: text);
            font-size: rem-calc(24);
          }
        }
      }
    }

    .copyright {
      background: transparent;
      border-top: 1px solid $white-smoke;
      clear: both;
      color: darken($grey-light, 25);
      font-size: rem-calc(16);
      padding-top: $global-padding * 2;
    }
  }
}
