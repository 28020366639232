// Login page
.customer-account-login {

  .form-login {

    .actions-toolbar {

      .primary {

        .action.login {
          font-size: rem-calc(24);
        }
      }

      .secondary {

        .action.remind {
          @include button($type: text);
          font-size: rem-calc(24);
        }
      }
    }
  }

  .block-new-customer {

    .action.create {
      font-size: rem-calc(24);
    }
  }
}

.customer-account-forgotpassword {

  .action {

    &.reload,
    &.captcha-reload {
      @include button($type: primary, $outline: true);
    }
  }
}

// My Account areas
.account {

  // My Account sidebar nav
  .sidebar-main {

    .block-collapsible-nav {

      .item {

        &.current {

          a,
          > strong {
            border-color: $primary-color;
          }
        }

        a {
          color: $body-font-color;

          &:hover,
          &:visited {
            color: $body-font-color;
          }
        }
      }
    }
  }

  // Account Dashboard
  .box-actions,
  .block-dashboard-addresses {

    .action {

      &.edit {
        @include button($type: text);
        padding: 0;
      }

      &.change-password {
        @include button($type: primary, $outline: true);
      }
    }
  }

  .block-dashboard-orders {

    .block-title {

      .action.view {
        @include button($type: primary, $outline: true);
      }
    }

    .orders-recent {

      .col.actions {

        .action {
          @include button($type: text);
          padding: 0;
        }
      }
    }
  }

  // Order information block.
  .column.main {

    .block.block-order-details-view {

      > .block-content:not(.widget) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > .box {
          width: 100%;

          @include breakpoint(medium) {
            width: 50%;
          }
        }
      }
    }
  }

  // My Orders - History
  &.sales-order-history {

    .orders-history {

      .col.actions {

        .action {
          @include button($type: text);
          padding: 0;
        }
      }
    }
  }

  // My Orders - View/Print Order page
  &.sales-order-view,
  &.sales-order-print {

    .page-title-wrapper {

      .order-status {
        border-color: $green;
        color: $green;
      }

      .order-actions-toolbar {

        .action.order {
          @include button($type: primary, $outline: true);
        }
      }
    }
  }
}
