////////////////////////////////////////////////////////////////////////////////
// TYPOGRAPHY
// -----------------------------------------------------------------------------

// Proxima Nova (Good)
@include font-face(Montserrat-Regular, '../fonts/Montserrat/montserrat-regular-webfont', normal, normal, $font-format);
@include font-face(Montserrat-Bold, '../fonts/Montserrat/montserrat-bold-webfont', normal, normal, $font-format);
@include font-face(Montserrat-SemiBold, '../fonts/Montserrat/montserrat-semibold-webfont', normal, normal, $font-format);
@include font-face(Montserrat-Italic, '../fonts/Montserrat/montserrat-italic-webfont', normal, normal, $font-format);

::selection {
  background: $primary-color;
  color: $white;
}

.visually-hidden {
  @include visually-hidden;
}

body {
  font-family: $body-font-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $semi-bold-font-family;
}

a,
.alink {
  color: $primary-color;

  &:visited {
    color: $primary-color;

    &:hover {
      color: darken($primary-color, 20);
    }
  }

  &:hover {
    color: darken($primary-color, 20);
  }
}
