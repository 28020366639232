.rating-summary {

  .rating-result {

    > span {

      &:before {
        color: $yellow;
      }
    }
  }
}
