.breadcrumbs {

  a {
    color: $primary-color;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    transition: color $global-speed, border-color $global-speed;

    &:visited {
      color: $primary-color;
    }

    &:hover {
      color: darken($primary-color, 20);
      border-bottom-color: darken($primary-color, 20);
      text-decoration: none;
    }
  }
}
