.block.newsletter {

  .actions {

    .action.subscribe {
      font-size: rem-calc(22);

      &.sr-only {
        height: auto;
      }
    }
  }
}

body {

  &.agency-theme-light {

    .page-footer {
      background: $white-smoke;
      color: $body-font-color;
      border-color: darken($white-smoke, 10);

      .branding-logo {
        background: url('../images/logo_alt.svg') no-repeat top left;
        background-size: 70%;
        display: inline-block;
        padding: $global-padding * 3;
        width: 100%;
      }

      .footer.links {
        @include breakpoint(small only) {
          border-color: darken($white-smoke, 10);
        }
      }
    }

    .copyright {
      background: $white-smoke;
      color: $grey-text;
    }
  }

  &.agency-theme-dark {

    .page-footer {
      background: $brand-secondary;
      color: $white;
      border-color: darken($white-smoke, 10);

      .inner-wrapper {

        .branding-logo {
          background: url('../images/logo_inverse.svg') no-repeat top left;
          background-size: 70%;
          display: inline-block;
          padding: $global-padding * 3;
          width: 100%;
        }

        .branding-phone {

          a.phone {
            color: $white-smoke;

            &:hover {
              color: darken($white-smoke, 25);
            }
          }
        }
      }

      .footer.links {
        @include breakpoint(small only) {
          border-color: lighten($brand-secondary, 10);
        }

        a {
          color: $white-smoke;

          &:hover {
            color: darken($white-smoke, 25);
          }
        }
      }
    }

    .copyright {
      background: $brand-secondary;
      color: darken($white-smoke, 50);
    }
  }
}

.page-footer {
  border-top: 1px solid;
  padding: $global-padding * 4;

  .footer.content {
    @include breakpoint(small only) {
      border: 0;
      padding-top: 0;
    }
  }

  .branding-footer-container {
    @include breakpoint(small only) {
      float: left;
      width: 100%;
    }

    .inner-wrapper {
      float: left;
      width: 33%;
      margin-right: $global-margin * 4;

      @include breakpoint(small only) {
        width: 100%;
      }

      .branding-message {
        margin: $global-margin * 2 0;
      }

      .branding-phone {

        a {

          &.phone {
            @include button($type: text);
            @include icon($position: before, $icon: mobile);
            font-size: rem-calc(15, $base-font-size);
            padding-left: 0;
          }
        }
      }
    }
  }

  .footer.links {
    float: left;
    width: 40%;
    padding-right: 0;

    @include breakpoint(small only) {
      margin: $global-margin * 2 0;
      width: 100%;
      border: 1px solid;
      border-width: 1px 0;
      padding: $global-padding * 2 0;

      li {
        background: transparent;
        border: 0;
      }
    }

    a {

      &:hover {
        text-decoration: none;
      }
    }
  }

  .branding-trust-signals {
    float: right;
    width: 20%;

    @include breakpoint(small only) {
      clear: both;
      width: 100%;
    }

    .site-seals {
      padding-right: 0;
      padding-left: 0;

      &.no-bullets {
        list-style: none;

        li {
          list-style: none;
        }
      }

      li {
        margin: $global-margin 0;

        @include breakpoint(small only) {
          display: inline-block;
          width: 45%;
        }
      }
    }

    .ssl-seal {
      background: url('../images/ProtectedByCloudflareBadge_LtBlue.png') no-repeat top left;
      background-size: 100%;
      padding: $global-padding * 5 $global-padding;
    }

    .payment-seal {
      background: url('../images/firstdata_badge.png') no-repeat top left;
      padding: $global-padding * 3 $global-padding;
    }
  }
}
