.checkout-index-index {

  .authentication-wrapper {

    .action.action-auth-toggle {
      @include button($type: primary);
      font-size: rem-calc(24);
    }
  }

  .form-login,
  .block-customer-login {

    .actions-toolbar {

      .primary {
        float: none;

        .action {

          &.login,
          &.action-login {
            @include button($type: primary);
            font-size: rem-calc(24);
          }
        }
      }

      .secondary {
        float: none;
        margin-right: 0;
        padding-top: 0;

        .action {

          &.remind,
          &.action-remind {
            @include button($type: text);
            font-size: rem-calc(24);
          }
        }
      }
    }
  }

  .opc-progress-bar {

    .opc-progress-bar-item {

      &._active {

        &:before {
          background: $primary-color;
          border-color: $primary-color;
        }

        span {

          &:before {
            background: $primary-color;
            border-color: $primary-color;
          }

          &:after {
            border-color: $primary-color;
          }
        }
      }

      &._complete {

        > span {
          color: $body-font-color;
        }
      }
    }
  }

  .form-shipping-address,
  .billing-address-form {

    .address {
      display: flex;
      flex-direction: column;

      [name*='firstname'] {
        order: 1;
      }

      [name*='lastname'] {
        order: 2;
      }

      [name*='company'] {
        order: 3;
      }

      // Move Country select field to be first w/in address fields so other fields can normalize (i.e., street, city, state, zip)
      [name*='country_id'] {
        order: 5;
      }

      fieldset.field.street {
        order: 6;
      }

      [name*='city'] {
        order: 7;
      }

      [name*='region_id'],
      [name*='region'] {
        order: 8;
      }

      [name*='postcode'] {
        order: 9;
      }

      [name*='telephone'] {
        order: 10;
      }

      // Save in address book checkbox
      .field.choice {
        order: 20;
      }
    }
  }

  .opc-wrapper {

    .shipping-address-items {
      max-height: $global-padding * 50;
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
    }

    .shipping-address-item {
      order: 1;
      width: 32%;
      margin-right: $global-margin;
      padding: $global-padding * 1.25 $global-padding * 1.5;

      &:before {
        display: none;
      }

      &.not-selected-item {
        border: 1px solid $grey-light;
      }

      &.selected-item {
        border-color: $primary-color;
        order: 0;

        &:after {
          background: $primary-color;
        }
      }

      .action {

        &.action-select-shipping-item {
          @include button($type: primary, $outline: true);
          margin-top: $global-margin;
        }

        &.edit-address-link {
          @include button($type: text);
          padding: 0;

          @media (max-width: 768px) {
            right: 0;
          }
        }
      }
    }

    .new-address-popup {

      .action {
        @include button($type: primary, $outline: true);
      }
    }

    .checkout-payment-method {

      .payment.ccard {

        .note.billing-address {
          background: $grey-light;
          color: $grey-bt;
          padding: $global-padding;
          margin: $global-margin 0 $global-margin * 2;

          .message {
            @include icon($position: before, $icon: info);

            &:before {
              font-size: rem-calc(20);
              margin-right: $global-margin;
              position: relative;
              top: 2px;
            }
          }
        }
      }
    }
  }

  .modal-popup {

    .modal-footer {

      .action {

        &.action-hide-popup {
          @include button($type: text);
        }
      }
    }
  }

  .checkout-payment-method {

    .checkout-billing-address {

      input[type='checkbox'] {
        position: relative;
        top: 0;
      }

      .action {

        &.action-edit-address {
          @include button($type: text);
          padding: 0;
        }
      }

      .actions-toolbar {

        .primary {
          float: none;

          .action {

            &.action-update {
              @include button($type: primary);
              float: left;
              font-size: rem-calc(20);
            }

            &.action-cancel {
              @include button($type: text);
              float: left;
              font-size: rem-calc(20);
            }
          }
        }
      }
    }

    .payment-option-title {

      .action-toggle {
        color: $body-font-color;
      }
    }

    .discount-code {

      .actions-toolbar {

        .primary {

          .action,
          .action:not(:focus) {
            @include button($type: primary);
            border-radius: 0;
            box-shadow: none;
            font-size: rem-calc(16);
          }
        }
      }
    }

    .gift-code {

      .actions-toolbar {
        display: block;
        width: auto;

        .amgiftcard-check,
        .primary {
          display: inline-block;
          margin-right: 1rem;

          .action,
          .action:not(:focus) {
            @include button($type: primary);
            border-radius: 0;
            box-shadow: none;
            font-size: rem-calc(16);
          }
        }
      }
    }

    .credit {

      .actions-toolbar {

        .primary {

          .action {
            @include button($type: primary);
            border-radius: 0;
            box-shadow: none;
            font-size: rem-calc(16);
          }
        }
      }
    }
  }
}

.checkout-onepage-success {

  .page-title-wrapper {

    .print {
      @include icon($position: before, $icon: print);
    }
  }

  .checkout-success {

    .action {

      &.primary.continue {
        @include button($type: primary, $outline: true);
      }
    }
  }

  form {

    .action.primary,
    input[type='submit'] {
      @include button($type: primary, $large: true);
    }
  }
}
