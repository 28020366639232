// My Account > My Payment Data
.account.customer-paymentinfo-index {

  .block-addresses-list {

    address {
      font-size: rem-calc(20);
      color: $font-black;
    }

    .item.actions {

      .action {
        @include button($type: secondary, $outline: true, $reverse: true);
        font-size: rem-calc(16);
        margin-right: $global-margin;

        &:after {
          display: none;
        }

        &:active,
        &:focus,
        &:hover:focus {
          background-color: $primary-color;
          color: $white;
        }
      }
    }
  }

  .fieldset {

    .field.cvv {

      .action.cvv {
        @include button($type: secondary, $outline: true, $reverse: true);
        font-size: rem-calc(16);
      }
    }
  }
}
