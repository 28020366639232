.header {

  .logo {
    @include breakpoint(small only) {
      left: 25%;
      margin: 0 auto;
      position: relative;
    }

    img {
      height: auto;
      max-width: 150px;
    }
  }
}

header.page-header {

  .header.panel {
    background: transparent;
    padding: 0;
  }

  .header.content {

    .header.links {
      margin-bottom: 0;
      float: right;

      @include breakpoint(medium down) {
        display: none;
      }

      li {
        display: inline-block;
        margin-top: $global-margin / 4;

        + li {
          border-left: 1px solid $grey-light;
          padding-left: $global-padding;
        }

        &.authorization-link {
          border-left: 0;
          margin-right: $global-margin;

          &:hover {

            a {
              background: transparent;
            }
          }
        }

        &.customer-welcome {
          border-left: 0;
          margin-left: $global-margin * -1;
          padding-left: 0;
          transition: color $global-speed;

          .action.switch {
            color: transparent;
            min-width: $global-padding * 10;
            text-align: right;
            margin-right: $global-margin / 2;
            position: relative;
            bottom: 1px;

            &:after {
              display: none;
            }

            > span {

              &.loggedin-customer-name {
                color: $secondary-color;
                position: relative;
                text-transform: capitalize;
                font-family: $body-font-family;
                font-weight: $book-font-weight;
                letter-spacing: 0;
              }

              &:not(.loggedin-customer-name) {
                color: transparent;
                width: 1px;
                height: 1px;
              }
            }
          }

          .customer-name {

            &:after {
              content: '\e622';
              color: $primary-color;
              font-family: 'luma-icons'; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
              font-size: rem-calc(12);
              margin-right: $global-margin / 2;
              transition: color $global-speed;
            }
          }

          &:hover {
            color: darken($primary-color, 20%);

            .customer-name {

              &:after {
                color: $primary-color;
              }
            }
          }

          .header.links {

            li {
              margin-top: 0;
              width: 100%;
              text-align: center;

              + li {
                border-left: 0;
                padding-left: initial;
              }
            }
          }
        }

        &.credit {

          .counter.amount {

            // remove the wrapping ()
            &:before,
            &:after {
              display: none;
            }
          }
        }

        a {
          color: $primary-color;
          transition: color $global-speed, background-color $global-speed;

          &:hover {
            color: darken($primary-color, 20%);
          }
        }
      }
    }

    .customer-menu {

      .header.links {

        li > a {
          text-align: left;
        }
      }
    }
  }
}

body {

  &.agency-theme-light {

    .branding-header-container {
      background: $white;
      color: $body-font-color;

      .branding-logo {
        background: url('../images/logo_alt.svg') no-repeat top left;
        background-size: 100%;
        display: inline-block;
        padding: $global-padding * 2;
        width: 10%;
        position: relative;
        top: $global-padding / 2;

        @include breakpoint(small only) {
          float: left;
          width: 40%;
          top: 0;
        }
      }

      .branding-salesrep {
        border-color: $white-smoke;
      }
    }
  }

  &.agency-theme-dark {

    .branding-header-container {
      background: $brand-secondary;
      color: $white;

      .branding-logo {
        background: url('../images/logo_inverse.svg') no-repeat top left;
        background-size: 100%;
        display: inline-block;
        padding: $global-padding * 2;
        width: 10%;
        position: relative;
        top: $global-padding / 2;

        @include breakpoint(small only) {
          float: left;
          width: 40%;
          top: 0;
        }
      }

      .branding-salesrep {
        border-color: lighten($brand-secondary, 25);
      }

      .branding-phone {

        a {
          color: $white;

          &:hover {
            color: darken($white-smoke, 25);
          }
        }
      }
    }
  }
}

.branding-header-container {
  padding: $global-padding 0 0;
  @include breakpoint(small only) {
    float: left;
    width: 100%;
  }

  .inner-wrapper {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px; // rem-calc(1280) = 80rem so why doesn't this matchup;
    padding-left: $global-padding * 1.25;
    padding-right: $global-padding * 1.25;
    width: 100%;
    position: relative;

    @include breakpoint(small only) {
      float: left;
      width: 100%;
    }
  }

  .branding-salesrep {
    border-left: 1px solid;
    display: inline-block;
    margin-left: $global-margin * 2;
    padding: $global-padding 0 $global-padding $global-padding * 2;
    vertical-align: top;
    width: 30%;

    @include breakpoint(small only) {
      display: none;
      // border-width: 0;
      // clear: left;
      // float: left;
      // margin: $global-margin 0;
      // padding: 0;
      // text-align: center;
      // width: 100%;
    }

    .sales-rep-name {
      font-weight: 600;
    }
  }

  .branding-phone {
    display: inline-block;
    width: 15%;
    position: absolute;
    right: 0;

    @include breakpoint(small only) {
      float: right;
      width: 45%;
    }

    a {
      @include button($type: text);
      @include icon($position: before, $icon: mobile);
      white-space: nowrap;
    }
  }
}

.page-header .panel.wrapper {
  @include breakpoint(small only) {
    clear: both;
  }
}
