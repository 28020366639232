.filter-content {

  .filter-actions {

    .action.clear {
      @include button($type: primary, $outline: true);
    }
  }

  .filter-options-content {

    a {

      &:hover {
        background: none;
      }
    }
  }
}
