
.availability-tooltip {

  .tooltip-toggle {
    background: $primary-color;
    border-radius: 50%;
    color: $white;
    font-weight: 700;
    display: inline-block;
    text-align: center;
    width: $global-padding * 2;

    &:hover {
      color: $white;
    }
  }

  .tooltip-content {
    left: auto;
    right: 0;
    top: $global-padding * 2.5;

    &:before,
    &:after {
      right: $global-padding;
      top: $global-padding * -0.65;
    }

    &:before {
      border-right-color: transparent;
      border-bottom-color: $grey-offset;
    }

    &:after {
      border-right-color: transparent;
      border-bottom-color: $white;
      margin-top: rem-calc(-4);
    }
  }
}
