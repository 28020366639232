.product,
.product-item {

  .name,
  .product-item-name {
    font-size: rem-calc(24);

    a {
      font-weight: $bold-font-weight;

      &:hover,
      &:visited {
        color: $body-font-color;
      }
    }
  }

  .action.more {
    @include button($type: primary, $outline: true);
  }
}

.catalog-product-view {

  .product-info-main {

    .price-box.price-final_price {
      width: 45%;

      .normal-price {
        display: inline-block;
      }

      .old-price {
        margin-left: $global-margin;
        display: none !important; /* stylelint-disable-line declaration-no-important */

        .price-label {
          display: none;
        }

        .price-wrapper {
          text-decoration: line-through;
          color: $grey-bt;
        }
      }
    }

    .product-info-stock-sku {
      width: 50%;

      // Stock status
      .stock {
        font-weight: normal;
        text-transform: capitalize;

        > .title {
          font-weight: 700;
          text-transform: uppercase;
        }

        &.available {

          > span:not(.title):not(.availability-tooltip) {
            color: $green;
          }
        }

        &:not(.available) {

          > span:not(.title):not(.availability-tooltip) {
            color: $red;
          }
        }
      }
    }
  }
}

.swatch-attribute-options {

  .swatch-option {

    &.image:not(.disabled),
    &.color:not(.disabled) {

      &:hover {
        outline: 2px solid $primary-color;
      }
    }

    &.selected {
      outline: 2px solid $primary-color;
    }
  }
}
