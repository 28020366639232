.message {
  padding: $global-padding * 1.25 $global-padding * 2 $global-padding * 1.25 $global-padding * 4;

  &.demo-ribbon {
    padding: 0;
  }

  &.success {
    background: lighten(map-deep-get($foundation-palette, success), 50);
    color: darken(map-deep-get($foundation-palette, success), 10);

    a {
      color: darken(map-deep-get($foundation-palette, success), 10);
      font-weight: $bold-font-weight;

      &:hover {
        color: lighten(map-deep-get($foundation-palette, success), 40);
        text-decoration: underline;
      }

      &:visited {
        color: darken(map-deep-get($foundation-palette, success), 10);
      }
    }
  }

  &.warning,
  &.notice {
    background: lighten(map-deep-get($foundation-palette, warning), 50);
    color: darken(map-deep-get($foundation-palette, warning), 10);

    a {
      color: darken(map-deep-get($foundation-palette, warning), 10);

      &:hover {
        text-decoration: underline;
      }

      &:visited {
        color: darken(map-deep-get($foundation-palette, warning), 10);
      }
    }
  }

  &.error {
    background: lighten(map-deep-get($foundation-palette, alert), 50);
    color: map-deep-get($foundation-palette, alert);

    a {
      color: darken(map-deep-get($foundation-palette, alert), 10);

      &:hover {
        text-decoration: underline;
      }

      &:visited {
        color: darken(map-deep-get($foundation-palette, alert), 10);
      }
    }
  }
}
