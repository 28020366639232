
.abs-account-blocks .block-title > strong,
.abs-account-blocks .block-title > span, /* stylelint-disable-line no-duplicate-selectors */
.account .legend > strong,
.account .legend > span,
.form-giftregistry-search .legend > strong,
.form-giftregistry-search .legend > span,
.block-giftregistry-results .block-title > strong,
.block-giftregistry-results .block-title > span,
.block-giftregistry-shared-items .block-title > strong,
.block-giftregistry-shared-items .block-title > span,
.block-wishlist-search-form .block-title > strong,
.block-wishlist-search-form .block-title > span,
.block-wishlist-search-results .block-title > strong,
.block-wishlist-search-results .block-title > span,
.multicheckout .block-title > strong,
.multicheckout .block-title > span,
.multicheckout .block-content .title > strong,
.multicheckout .block-content .title > span,
.customer-review .review-details .title > strong,
.customer-review .review-details .title > span,
.paypal-review .block .block-title > strong,
.paypal-review .block .block-title > span,
.account .column.main .block:not(.widget) .block-title > strong,
.account .column.main .block:not(.widget) .block-title > span,
.magento-rma-guest-returns .column.main .block:not(.widget) .block-title > strong,
.magento-rma-guest-returns .column.main .block:not(.widget) .block-title > span,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-title > strong,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-title > span,
.sales-guest-view .column.main .block:not(.widget) .block-title > strong,
.sales-guest-view .column.main .block:not(.widget) .block-title > span {
  font-size: rem-calc(24);
}

.order-review-form .action.primary,
.bundle-actions .action.primary.customize,
.bundle-options-container .block-bundle-summary .box-tocart .action.primary,
.box-tocart .action.tocart,
.cart-summary .checkout-methods-items .action.primary.checkout,
.block-minicart .block-content > .actions > .primary .action.primary,
.methods-shipping .actions-toolbar .action.primary,
.block-authentication .action.action-register,
.block-authentication .action.action-login,
.checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary,
.form-address-edit .actions-toolbar .action.primary,
.box-tocart .action.instant-purchase,
.multicheckout .action.primary {
  font-size: inherit;
}

.abs-account-block-font-size,
.block-addresses-list address,
.box-billing-address .box-content,
.box-shipping-address .box-content,
.box-address-billing .box-content,
.box-address-shipping .box-content,
.box-information .box-content,
.block-balance-giftcard .block-content,
.block-reviews-dashboard .product-name {
  font-size: inherit;
}
