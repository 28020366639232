.minicart-wrapper {

  .action.showcart {

    .counter.qty {
      background: $primary-color;
    }
  }

  .minicart-items {

    .product-item-name {

      a {
        color: $body-font-color;
      }
    }

    .product-item-details {
      @media (max-width: 768px) {
        padding-left: 0;
      }
    }

    .update-cart-item {
      box-shadow: none;
      padding: $global-padding * 0.75 $global-padding;
    }
  }

  .product {

    .actions {
      margin-top: $global-margin * -3;
    }
  }

  .actions {

    .secondary {

      .action.viewcart {
        @include button($type: text);
      }
    }
  }
}

.cart {

  &.item {

    .product-item-details {

      .product-item-name {

        > a {
          font-size: rem-calc(40);
        }
      }

      .product-sku {
        color: $grey-text-light;
        font-family: $semi-bold-font-family;
      }

      .stock {

        .title {
          font-family: $semi-bold-font-family;
        }
      }
    }

    .item-actions {

      .action {

        &.action-edit,
        &.action-delete {
          background: transparent;
          padding: $global-padding / 2;
        }

        &.towishlist,
        &.action-towishlist {
          @include button($type: primary, $outline: true);
        }
      }
    }
  }

  &.main.actions {

    .action {
      display: inline;
      box-shadow: none;

      &.continue {
        @include button($type: text);
        @include icon($position: before, $icon: chevron-left);
        float: left;
        font-size: rem-calc(20);
      }

      &.clear {
        @include button($type: text);
        @include icon($position: before, $icon: trash-alt);
        font-size: rem-calc(20);
        margin-left: $global-margin;

        &:before {
          font-weight: $book-font-weight;
        }
      }

      &.update {
        @include button($type: primary, $outline: true);
        @include icon($position: before, $icon: sync);
        font-size: rem-calc(20);
        margin-left: $global-margin;
      }
    }
  }
}

.discount.cart-totals {

  .discount-form {

    .buttons-set {
      // override the #credit-form .discount .buttons-set declaration
      text-align: center !important; /* stylelint-disable-line declaration-no-important */

      .button {
        @include button($type: primary);
        font-size: rem-calc(18);
      }
    }
  }
}

.cart-discount {
  margin-bottom: $global-margin * 2;

  .block {

    > .title {

      strong {
        color: $body-font-color;
      }
    }
  }

  .actions-toolbar,
  .amactions-toolbar {

    .action {
      box-shadow: none;

      &.primary,
      &.primary:not(:focus) {
        @include button($type: primary);
        font-size: rem-calc(16);
        border-radius: 0;
      }
    }
  }
}
